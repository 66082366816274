<template>
  <div>
    <div class="border-t bg-white">
      <div class="container-xl px-4 md:px-2 py-4 h-20 md:h-20 mx-auto flex flex-row items-center justify-between">
        <!--Left Filter-->
        <div class="flex flex-row items-center justify-between md:justify-start">
          <h4 class="text-xl font-semibold leading-tight text-gray-800 flex items-center mr-0 md:mr-5">
            <span>{{ $t("connections.wrapperTitle") }}</span>
          </h4>
        </div>
        <!--Right Filter-->
        <div v-if="$verifyPermission(permissions, ['v2.client.project.create.credentials'])">
          <Button @click="toNewConnection" type="button" class="p-button-secondary" :label="$t('connections.wrapperButton')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button"
import { mapGetters } from 'vuex'

export default {
  name: "WrapperConnections",
  emits: ["Filter-wrapper"],
  computed: {
    ...mapGetters({permissions: 'Projects/permissionsForProject'})
  },
  data: function () {
    return {
      projectId: this.$route.params.id,
      channelId: this.$route.params.channelId
    }
  },
  components: {
    Button
  },
  methods: {
    toNewConnection: function () {
      this.$emit("newConnection")
    }
  }
}
</script>
