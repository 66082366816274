<template>
  <div>
    <WrapperConnections @newConnection="openFormConnection" />
    <div v-if="showSkeleton" class="flex flex-col w-full items-center justify-center">
      <div class="w-full max-w-2xl bg-white mt-8 overflow-hidden border border-gray-200 sm:rounded-lg">
        <table-skeleton />
      </div>
    </div>
    <div v-else class="flex flex-col w-full items-center justify-center">
      <TransitionFade>
        <Card class="border border-gray-200 w-full max-w-2xl bg-white mt-8 overflow-hidden sm:rounded-lg">
          <template #header>
            <div class="py-6 px-4 flex justify-between items-center">
              <div class="flex justify-evenly items-center">
                <span data-testid="ServiceIconShell" class="mr-3 flex items-center justify-center" style="width: 42px; height: 42px">
                  <img :alt="`${channel.avatar} logo`" aria-hidden="false" loading="lazy" width="42" height="42" :src="channel.avatar" />
                </span>
                <p class="font-semibold">{{ channel.name }}</p>
              </div>
              <span @click="toChannels" class="cursor-pointer text-gray-500 hover:text-gray-700">
                <Icon icon="tabler:x" class="text-gray-500 w-8 h-8" />
                <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg> -->
              </span>
            </div>
          </template>
          <template #content>
            <div id="credentials" v-if="credentials.length">
              <div v-for="credential in credentials" :key="credential" class="h-14 bg-gray-100 rounded-lg m-3 p-5 flex justify-between items-center shadow">
                <p class="font-semibold text-sm">{{ credential.name || channel.name + " credential" }}</p>
                <div class="flex justify-between items-center">
                  <!-- TODO: Esperando cambios de backend-->
                  <!-- <span id="credentialSuccess" v-tooltip.top="'Credenciales correctas'" class="indicator mr-5 inline-block bg-light-green-500"/>
                  <span id="credentialAlert" v-tooltip.top="'Revise las credenciales'">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-red-500 mr-5">
										<path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
									</svg>
								</span> -->
                  <Button v-if="showConnectionButton()" @click="openFormConnectionEdit(credential)" type="button" class="p-button-secondary" v-tooltip.top="$t('connections.editButton')">
                    <Icon icon="tabler:edit" class="w-5 h-5" />
                  </Button>
                </div>
              </div>
            </div>
            <div id="notFound" v-if="!credentials.length" class="border-l-4 border-gray-400 bg-gray-100 m-3 p-5 shadow">
              <div class="flex">
                <div class="flex-shrink-0">
                  <Icon icon="tabler:info-circle" class="text-gray-500 w-5 h-5" />
                </div>
                <div class="ml-3">
                  <p class="text-sm font-semibold text-gray-700">{{ $t("connections.notFound") }}</p>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </TransitionFade>
    </div>
    <Dialog :showHeader="false" v-model:visible="formConnection.show" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '30vw' }" :modal="true" :contentStyle="{'padding': '0 !important'}" contentClass="rounded-lg" :dismissableMask="true">
      <FormConnection v-if="formConnection.show" :project="formConnection.project" :channel="formConnection.channel" :projectCredential="projectCredentialEdit" @credentialCreate="saveConnection" />
    </Dialog>
  </div>
</template>

<script>
import WrapperConnections from "@/components/UI/wrappers/WrapperConnections"
import TransitionFade from "@/components/TransitionFade"
import axiosClient from "@/config/AxiosClient"
import FormConnection from "@/components/ChannelConnections/FormConnection"
import TableSkeleton from "@/components/UI/TableSkeleton.vue"
import Dialog from "primevue/dialog"

import Card from "primevue/card"
import Button from "primevue/button"
import { Icon } from "@iconify/vue"

export default {
  name: "Connections",
  computed: {
    channel() {
      return this.$store.state.Channels.channels.length ? this.$store.state.Channels.channels.find((channel) => channel.id == this.$route.params.channelId) : {}
    }
  },
  components: {
    WrapperConnections,
    TransitionFade,
    FormConnection,
    TableSkeleton,
    Card,
    Button,
    Icon,
    Dialog
  },
  data() {
    return {
      showSkeleton: true,
      project: this.$route.params.id,
      channelId: this.$route.params.channelId,
      credentialId: this.$route.params.project_credential_id,
      credentials: [],
      formConnection: {
        show: false,
        channel: {},
        project: {},
        title: "Nueva conexion"
      },
      projectCredentialEdit: {}
    }
  },
  async created() {
    await this.getChannel()
    await this.getCredentials()
  },
  methods: {
    showConnectionButton() {
      return !(this.$store.state.Auth.user?.roles.some((role) => role == "client") && this.$store.state.Auth.user?.roles.some((role) => role == "client_viewer"))
    },

    getChannel: async function () {
      if (!this.channel || !this.channel.id) {
        await this.$store.dispatch("Channels/getChannel", { id: this.channelId })
        setTimeout(() => {
          this.isLoading = false
        }, 1000)
      }
    },
    getCredentials: async function () {
      let res = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/project/${this.project}/credentials?filter[channel_id][%3D]=${this.channelId}`)
      if (res.status == 200) this.showSkeleton = false
      this.credentials = res.data.data
    },
    toChannels() {
      this.$router.push({ name: "Channels" })
    },
    async openFormConnectionEdit(credential) {
      this.projectCredentialEdit = credential
      this.formConnection.channel = this.channel
      this.formConnection.project = this.$store.state.Projects.activeProject
      this.formConnection.show = true
    },
    openFormConnection() {
      this.projectCredentialEdit = {}
      this.formConnection.channel = this.channel
      this.formConnection.project = this.$store.state.Projects.activeProject
      this.formConnection.show = true
    },
    clickAwayFormConnection() {
      this.formConnection.show = false
    },
    saveConnection(data) {
      if (this.projectCredentialEdit) {
        if (!this.credentials.find((credential) => credential.id == data.id)) {
          this.credentials.push(data)
        } else {
          let index = this.credentials.map((f) => f.id).indexOf(data.id)
          this.credentials[index] = data
        }
      }
    }
  }
}
</script>
